// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catervre-js": () => import("./../../../src/pages/catervre.js" /* webpackChunkName: "component---src-pages-catervre-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contacts-result-js": () => import("./../../../src/pages/contacts-result.js" /* webpackChunkName: "component---src-pages-contacts-result-js" */),
  "component---src-pages-ilmacaone-js": () => import("./../../../src/pages/ilmacaone.js" /* webpackChunkName: "component---src-pages-ilmacaone-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pi-js": () => import("./../../../src/pages/pi.js" /* webpackChunkName: "component---src-pages-pi-js" */),
  "component---src-pages-piccante-js": () => import("./../../../src/pages/piccante.js" /* webpackChunkName: "component---src-pages-piccante-js" */),
  "component---src-pages-pivot-js": () => import("./../../../src/pages/pivot.js" /* webpackChunkName: "component---src-pages-pivot-js" */)
}

